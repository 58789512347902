import parse from "html-react-parser";
import React from "react";
import Header from "../shared/controls/header";
import Footer from "../shared/controls/footer";
import * as utility from "../../utilities/utils";

function HcomThankyouComponent(props) {
  let params = utility.getParams();
  let utilityLabel1 ='';
  if(params.language === "es"){
    utilityLabel1 = require("../resources/resources.es");
 }else{
   utilityLabel1 = require("../resources/resources.en");
 }

  return (
    <div className="d-flex flex-column h-100 dce-bgpage  pace-done" id="hcomPage">
      <Header />
      <main className="main-wrapper flex-shrink-0 subtitle-0">
      <div className="container my-3">
        <div className="row pagetitle-container">
          <div className="col-12 text-center pb-2 pb-md-4">
            <span className="logo">
              <img
                id="imgHcomCheckmark"
                src={
                  require("../../global/img/wf/icon_checkmark_approved.png")
                }
                 alt=""
                className="img-fluid"
                style={{ width: "100%", maxWidth: "88px" }}
              />
            </span>
            <h1 className="eca-confirm-title text-center pb-2 pb-md-0 pt-3">
              {parse(utilityLabel1.utilityLabel.thankyouPage2Message1)}</h1>
            <p className="eca-confirm-summary1 text-left text-md-center pt-3">{parse(utilityLabel1.utilityLabel.thankyouPage2Message2)}</p>
            <p className="eca-confirm-summary2 text-left text-md-center">
              {parse(utilityLabel1.utilityLabel.thankyouPageMessage1)}{" "}
                {props.last4DigitCC}
              {" "}
              {utilityLabel1.utilityLabel.thankyouPageMessage2}
            </p>
            <p className="eca-confirm-summary3 text-left text-md-center">
              {parse(
                utilityLabel1.utilityLabel
                  .thankyouConfirmAdviseMessage
              )}
            </p>
          </div>
        </div>
        <div className="row cta-container" id="hcomButtons">
        <div className="col-12 text-center">
            <a
              type="button" role="button" tabIndex="0" className="btn btn-all-primary m-0 mb-3 w-auto d-inline-block eca-btn-sign1"
              onClick={() => { utility.trackEventsForRedirect("Thankyou", utilityLabel1.utilityLabel.signOn, props.sessionID,utilityLabel1.utilityLabel.hrefSignOn) }}
              id="btnHcomSignOn"
            >
              {utilityLabel1.utilityLabel.signOn}
            </a>
          </div>
          <div className="col-12 text-center">
            <a
              type="button" role="button" tabIndex="0" className="btn btn-secondary m-0 w-auto d-inline-block eca-btn-enroll mt-0"
              onClick={() => { utility.trackEventsForRedirect("Thankyou", utilityLabel1.utilityLabel.enrollNowBtn, props.sessionID,utilityLabel1.utilityLabel.hrefEnroll) }}
              id="btnHcomEnrollNow"
            >
              {utilityLabel1.utilityLabel.enrollNowBtn}
            </a>
          </div>
        </div>

      </div>
      </main>
      <Footer />
    </div>

  );
}

export default HcomThankyouComponent;
